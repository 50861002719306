import { get, set } from 'idb-keyval';

export const safeIdb = {
    get(key: string) {
        if (typeof indexedDB !== 'undefined') {
            return get(key).catch(console.error);
        }
    },

    set(key: string, value: unknown) {
        if (typeof indexedDB !== 'undefined') {
            return set(key, removeUnserializableValues(value)).catch(console.error);
        }
    },
};

function removeUnserializableValues(value: unknown, _touched = new Set<unknown>()): unknown {
    if (value instanceof Function) {
        return undefined;
    } else if (value instanceof Promise) {
        return undefined;
    } else if (Array.isArray(value)) {
        if (_touched.has(value)) throw new Error('removeUnserializableValues ran into a circle in an array');
        _touched.add(value);
        const newValue = value.map((v => removeUnserializableValues(v, _touched)));
        _touched.delete(value);
        return newValue;
    } else if (value?.constructor === Object) {
        if (_touched.has(value)) throw new Error('removeUnserializableValues ran into a circle in an object');
        _touched.add(value);
        const newValue = Object.fromEntries(Object.entries(value)
            .map(([key, value]) => [key, removeUnserializableValues(value, _touched)]));
        _touched.delete(value);
        return newValue;
    } else {
        // To determine if a value can be stored, see:
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
        return value;
    }
}
