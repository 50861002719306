import api from 'isc-shared/api';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useWorkflow } from '.';
import { PostCreationResponse } from '../lib/api/model';
import { safeIdb } from '../lib/storage';
import workflows from '../workflows';

export type WorkflowSubmission<Values extends object = object> = {
    workflow: keyof typeof workflows;
    createdAt: Date;
    updatedAt: Date | null;
    values: Partial<Values>;
    submittedAt: Date | null;
    postId: ISeeChange.Post['id'] | null;
    authorshipToken: string | null;
    lastSuccessful: WorkflowSubmissionData | null;
    update: (changes: Partial<Values>) => void;
    submit: () => void,
};

export type WorkflowSubmissionData<Values extends object = object> = Omit<WorkflowSubmission<Values>, 'lastSuccessful' | 'update' | 'submit'>;

const SubmissionContext = createContext<WorkflowSubmission | null>(null);

export default function SubmissionProvider(props: Omit<React.ComponentProps<typeof SubmissionContext.Provider>, 'value'>) {
    const workflow = useWorkflow();
    const [submission, setSubmission] = useState<WorkflowSubmissionData>();
    const [lastSuccessful, setLastSuccessful] = useState<WorkflowSubmissionData | null>(null);

    const update = useCallback((changes: object) => {
        if (!submission) throw new Error('Attempted to update values of undefined submission?');

        console.info('Changing submission value', changes);
        // Mutate the object directly so it can be read immediately.
        Object.assign(submission.values, changes);
        Object.assign(submission, { updatedAt: new Date() });
        // But also set the update normally so it's reactive.
        setSubmission({ ...submission, values: { ...submission.values } });
    }, [submission]);

    const submit = useCallback(async () => {
        if (!workflow || !submission) throw new Error('Attempted to submit with undefined workflow or submission?');

        const payload = await workflow.collatePayload(submission);
        try {
            const response = await api<PostCreationResponse>('POST', '/../v3/posts', payload);

            setSubmission({
                ...submission,
                submittedAt: new Date(),
                postId: response.id,
                authorshipToken: response.authorship_token,
            });

            safeIdb.set(`submissions:${submission.workflow}`, null);
        } catch (error) {
            console.error(error);
            throw new Error('SUBMISSION_POST_FAILED');
        }
    }, [submission, workflow]);

    useEffect(() => {
        (async () => {
            if (!workflow) return;

            const EXPECTED_ERROR = new Error('EXPECTED');

            try {
                // Use a `try` block in case anything goes wrong reading the existing submission,
                // e.g. the user has an old incompatible format stored.

                const lastSuccessfulSubmission = await safeIdb.get(`submissions:${workflow.key}:last-successful`) as WorkflowSubmissionData | undefined;
                setLastSuccessful(lastSuccessfulSubmission ?? null);

                const shouldReset = location.search.includes('reset-submission');
                if (shouldReset) throw EXPECTED_ERROR;

                const existingSubmission = await safeIdb.get(`submissions:${workflow.key}`) as WorkflowSubmissionData | undefined;
                if (!existingSubmission) throw EXPECTED_ERROR;

                // Start over if it hasn't been modified so we can get fresh timestamps.
                const hasBeenModified = existingSubmission.updatedAt && existingSubmission?.createdAt.toISOString() !== existingSubmission?.updatedAt.toISOString();
                if (!hasBeenModified) throw EXPECTED_ERROR;

                console.info(`Restoring existing submission for workflow "${workflow.key}"`, existingSubmission);
                setSubmission(existingSubmission);
            } catch (error) {
                if (error !== EXPECTED_ERROR) {
                    console.error(error);
                }

                console.info(`Creating a new submission for workflow "${workflow.key}"`);
                setSubmission({
                    workflow: workflow.key,
                    createdAt: new Date(),
                    updatedAt: null,
                    values: {},
                    submittedAt: null,
                    postId: null,
                    authorshipToken: null,
                });
            }
        })();
    }, [workflow]);

    useEffect(() => {
        if (!submission) return;
        if (submission.submittedAt) {
            safeIdb.set(`submissions:${submission.workflow}:last-successful`, submission);
            setLastSuccessful(submission);
        } else {
            safeIdb.set(`submissions:${submission.workflow}`, submission);
        }
    }, [submission]);

    return (
        <SubmissionContext.Provider {...props} value={submission ? { ...submission, update, submit, lastSuccessful } : null} />
    );
}

SubmissionProvider.useContext = () => useContext(SubmissionContext);
